<template>
  <div>
    <v-footer padless class="footer">
      <v-container fluid>
        <v-row class="no-indentation" style="margin:72px 0">
          <v-col
            class="no-indentation"
            offset-lg="2"
            offset-sm="1"
            cols="12"
            sm="5"
            md="5"
          >
            <div class="footerHeading">ОТПРАВИТЬ ЗАЯВКУ</div>
            <div class="mt-6 footerSubHeading">
              Если задачу можно описать словами, то - мы сможем её решить!
            </div>
            <div class="mt-6 footerSubHeading">
              Заполните форму, мы ответим на все интересующие вас вопросы и
              подготовим предложение
            </div>
            <TaskForm class="mt-6"></TaskForm>
          </v-col>
          <v-col
            class=" mt-15 mb-8 mt-sm-0  text-center text-sm-left no-indentation"
            offset="1"
            cols="10"
            offset-sm="1"
            sm="5"
            offset-md="1"
            md="5"
            offset-lg="1"
            lg="3"
            xl="2"
          >
            <div>
              <div class="mb-4">
                <a @click="pushPrices(1)" class="footerHeading">Разработка</a>
              </div>
              <div v-for="price in categories" v-bind:key="price.id" class="mb-2">
                <a @click="pushPrices(price.id)" class="footerSubHeading">{{
                  price.name
                }}</a>
              </div>
              <div class="mb-4">
                <a @click="pushPrices(2)" class="footerHeading"
                  >Автоматизация бизнеса</a
                >
              </div>
              <div class="mb-4">
                <a @click="pushPrices(3)" class="footerHeading"
                  >Техническая поддержка</a
                >
              </div>
              <div>
                <a @click="pushPrices(4)" class="footerHeading">Дизайн</a>
              </div>
              <div class="logoMDFooter mt-14 mt-sm-0">
                <v-row>
                  <v-col cols="4" md="3" class="pa-0">
                    <img
                      class="mt-sm-2"
                      src="/img/icons/logoITMosaik.svg"
                      height="104"
                      width="112"
                      style="filter: invert(0.9);"
                    />
                  </v-col>
                  <v-col cols="8" md="9" class="pa-0 text-left pt-7 pt-sm-8">
                    <div class="mt-2 mt-n4 footerContactNumber">
                      <a
                        style="text-decoration: none; color:#ffffff"
                        href="tel:+79107879103"
                        ><span style="word-break: unset"
                          >+7(910)787-91-03</span
                        ></a
                      >
                    </div>
                    <div class="mt-4 footerSite">
                      <span style="word-break: unset">it-mosaic@yandex.ru</span>
                    </div>
                  </v-col>
                </v-row>
                <div class="mt-4 footerRights">
                  Все права защищены @, 2019-2020 ООО "ИТ-Мозаика"
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            class="text-left no-indentation"
            cols="8"
            sm="8"
            offset="2"
            md="2"
            xl="3"
            lg="3"
          >
            <div class="logoLGFooter">
              <v-row>
                <v-col cols="3" lg="4" class="pa-0">
                  <img
                    class="mt-sm-2"
                    src="/img/icons/logoITMosaik.svg"
                    height="104"
                    width="112"
                    style="filter: invert(0.9);"
                  />
                </v-col>
                <v-col cols="9" lg="8" class="pa-0 text-left pt-3">
                  <div class="mt-sm-2 mt-n4 footerContactNumber">
                    <a
                      style="text-decoration: none; color:#ffffff"
                      href="tel:+79107879103"
                      ><span style="word-break: unset"
                        >+7(910)787-91-03</span
                      ></a
                    >
                  </div>
                  <div class="mt-4 footerSite">
                    <span style="word-break: unset">it-mosaic@yandex.ru</span>
                  </div>
                </v-col>
              </v-row>
              <div class="mt-4 footerRights">
                Все права защищены @, 2019-2020 ООО "ИТ-Мозаика"
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
import TaskForm from "./TaskForm.vue";
export default {
  name: "PageFooter",
  components: { TaskForm },
  data() {
    this.$store.dispatch("prices/GET_CATEGORIES");
    this.$store.dispatch("prices/GET_PRICES");
    return {};
  },
  methods: {
    pushPrices(value) {
      console.log(value);
      this.$router.push("/prices/" + value).catch(() => {});
    }
  },
  computed: {
    categories() {
      return this.$store.getters["prices/categories"];
    },
    portfolio_cards() {
      return this.$store.getters["portfolio/portfolio_categories"];
    }
  }
};
</script>
