<template>
  <div>
    <v-scroll-y-reverse-transition v-if="offsetTop > 45">
      <div class="menuSticky" style="position: fixed; top: 0; width: 100%">
        <v-navigation-drawer
          v-model="menu"
          location="left"
          temporary
          :width="windowWidth * 0.85"
        >
          <v-list class="menuColor btnCloseMenu">
            <v-list-item>
              <v-btn
                class="taskFormClose"
                color="primary"
                variant="text"
                rounded
                style="width: 50px; height: 50px; min-width: 0"
                @click="menu = false"
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
          <hr />
          <v-list
            class="menuColor"
            style="height: 93vh; width: 40vh !important"
          >
            <v-list-item class="list">
              <v-btn
                v-on:click="showPrices = !showPrices"
                variant="text"
                class="menuBtnMobile"
                block
                color="light-blue darken-2"
                >Цены</v-btn
              >
            </v-list-item>
            <transition name="fade">
              <v-list class="subMenuPrice offset-1" v-if="showPrices">
                <v-list-item
                  v-for="price in categories"
                  v-bind:key="price.id"
                  class="list"
                >
                  <v-btn
                    variant="text"
                    @click="pushPrices(price.id)"
                    class="menuBtnMobile text-truncate"
                    block
                    color="light-blue darken-2"
                    style="width: 90%"
                    title="Сайт на конструкторе Tilda"
                    >{{ price.name }}</v-btn
                  >
                </v-list-item>
              </v-list>
            </transition>
            <v-list-item class="list">
              <v-btn
                v-on:click="showPortfolio = !showPortfolio"
                variant="text"
                class="menuBtnMobile"
                block
                color="light-blue darken-2"
                >Портфолио</v-btn
              >
            </v-list-item>
            <transition name="fade">
              <v-list class="subMenuPrice offset-1" v-if="showPortfolio">
                <v-list-item
                  v-for="cat in portfolio_cards"
                  v-bind:key="cat.id"
                  class="list"
                >
                  <v-btn
                    variant="text"
                    @click="pushPortfolio(cat.id)"
                    class="menuBtnMobile text-truncate"
                    block
                    color="light-blue darken-2"
                    style="width: 90%"
                    >{{ cat.name }}</v-btn
                  >
                </v-list-item>
              </v-list>
            </transition>
            <v-list-item class="list">
              <v-btn
                variant="text"
                @click="pushAboutCompany()"
                class="menuBtnMobile"
                block
                color="light-blue darken-2"
                >О компании</v-btn
              >
            </v-list-item>
            <v-list-item class="list">
              <v-btn
                variant="text"
                @click="pushNewsFeed()"
                class="menuBtnMobile"
                block
                color="light-blue darken-2"
                >Лента</v-btn
              >
            </v-list-item>
            <v-list-item class="list">
              <v-btn
                variant="text"
                @click="pushCareer()"
                class="menuBtnMobile"
                block
                color="light-blue darken-2"
                >Карьера</v-btn
              >
            </v-list-item>
            <v-list-item class="list">
              <v-btn
                variant="text"
                @click="pushContacts()"
                class="menuBtnMobile"
                block
                color="light-blue darken-2"
                >Контакты</v-btn
              >
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-row>
          <v-col cols="2" class="burgerMenuSticky">
            <div>
              <v-btn
                size="x-large"
                variant="text"
                icon
                @click.stop="menu = true"
                class="mobileMenu"
              >
                <v-icon size="x-large" color="#fff">mdi-menu</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="8" lg="1" class="logoSticky">
            <div class="logoSvgSticky pa-0">
              <div>
                <a @click="pushMain()"
                  ><img
                    src="/img/icons/logoITMosaik.svg"
                    style="
                      width: 100%;
                      height: 80px;
                      min-width: 96px;
                      filter: invert(0.9);
                    "
                /></a>
              </div>
            </div>
          </v-col>
          <v-col cols="10" lg="9" class="menu_content_sticky">
            <v-toolbar-items class="no-indentation">
              <v-row align="center" justify="center" no-gutters>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-1"
                  @click="pushPrices(1)"
                >
                  <h4 class="menu_content_text">Цены</h4>
                </v-btn>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-1"
                  @click="pushPortfolio(0)"
                >
                  <h4 class="menu_content_text">Портфолио</h4>
                </v-btn>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-1"
                  @click="pushAboutCompany()"
                >
                  <h4 class="menu_content_text">О компании</h4>
                </v-btn>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-1"
                  @click="pushNewsFeed()"
                >
                  <h4 class="menu_content_text">Лента</h4>
                </v-btn>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-1"
                  @click="pushCareer()"
                >
                  <h4 class="menu_content_text">Карьера</h4>
                </v-btn>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-1"
                  @click="pushContacts()"
                >
                  <h4 class="menu_content_text">Контакты</h4>
                </v-btn>
              </v-row>
            </v-toolbar-items>
          </v-col>
          <v-col class="col_number" cols="2">
            <strong class="number_sticky">+7(910) 787-91-03</strong>
          </v-col>
          <v-col
            class="colFeedBackMobileSticky"
            cols="1"
            style="font-size: 16px"
          >
            <OpenDialogButton />
          </v-col>
          <v-col class="colFeedBackSticky" cols="2" style="font-size: 16px">
            <div class="menuFeedbackSticky">
              <div>
                <OpenDialogButton />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-scroll-y-reverse-transition>
  </div>
</template>

<script>
import OpenDialogButton from "@/components/ui/OpenDialogButton.vue";
export default {
  name: "StickyToolBar",
  props: ["offsetTop"],
  components: {
    OpenDialogButton,
  },
  data() {
    this.$store.dispatch("portfolio/GET_PORTFOLIO");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_CATEGORIES");
    this.$store.dispatch("prices/GET_CATEGORIES");
    this.$store.dispatch("prices/GET_PRICES");
    return {
      showRequestFormSticky: false,
      showRequestFormStickyMobile: false,
      showPrices: false,
      showPortfolio: false,
      closeOnContentClick: false,
      menu: false,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    pushMain() {
      this.$router.push("/").catch(() => {});
    },
    pushContacts() {
      this.$router.push("/contacts").catch(() => {});
    },
    pushCareer() {
      this.$router.push("/career").catch(() => {});
    },
    pushAboutCompany() {
      this.$router.push("/about").catch(() => {});
    },
    pushNewsFeed() {
      this.$router.push("/newsfeed").catch(() => {});
    },
    pushPrices(value) {
      this.$router.push("/prices/" + value).catch(() => {});
    },
    pushPortfolio(value) {
      this.$router.push("/portfolio/" + value).catch(() => {});
    },
  },
  computed: {
    categories() {
      return this.$store.getters["prices/categories"];
    },
    portfolio_cards() {
      return this.$store.getters["portfolio/portfolio_categories"];
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    offsetTop() {
      if (this.offsetTop < 45) this.menu = false;
    },
  },
};
</script>

<style scoped></style>
