<template>
  <div class="contentText">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="10">
          <v-text-field
            v-model="fio"
            :counter="50"
            :rules="nameRules"
            label="ФИО"
            class="inputStyle"
            variant="outlined"
            prepend-inner-icon="mdi-map-marker"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="10">
          <v-text-field
            v-model="vacancy"
            :counter="50"
            :rules="nameRules"
            label="Вакансия"
            prepend-inner-icon="mdi-map-marker"
            class="inputStyle"
            required
            variant="outlined"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="10">
          <v-file-input
            label="Прикрепить файлы"
            @change="onUploadFiles"
            class="inputStyle"
            small-chips
            variant="outlined"
            prepend-inner-icon="mdi-map-marker"
            prepend-icon=""
            multiple
            show-size
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="10">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            prepend-inner-icon="mdi-map-marker"
            class="inputStyle"
            required
            variant="outlined"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="10">
          <v-text-field
            v-model="phone"
            label="Телефон"
            prepend-inner-icon="mdi-map-marker"
            class="inputStyle"
            required
            variant="outlined"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="12" md="12" sm="10">
          <v-textarea
            class="inputStyle mt-1"
            label="Текст сообщения"
            variant="outlined"
            rows="6"
            v-model="text"
            prepend-inner-icon="mdi-map-marker"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" md="7" sm="6">
          <v-btn
            class="btn_work_form"
            elevation="2"
            variant="outlined"
            rounded
            size="x-large"
            v-on:click="send()"
            ><span class="span_btn_work_form">Отправить резюме</span>
          </v-btn>
          <v-dialog v-model="dialog" width="300">
            <v-card>
              <v-card-text class="pt-6 pb-8 circle-check">
                <i class="fas fa-check check-icon"></i>
                <p>Отправлено</p>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog_error" width="300">
            <v-card>
              <v-card-text class="pt-8 pb-8 circle-check">
                <i class="fas fa-times-circle cansel-icon"></i>
                <p>Вы неправильно заполнили форму</p>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col class="remarkCol" cols="12" lg="6" md="5" sm="6">
          <span class="remark"
            >Нажимая кнопку «Отправить», вы соглашаетесь с
            <a href="#">политикой обработки персональных данных</a>.</span
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "WorkForm",
  methods: {
    onUploadFiles: function(files) {
      this.files = files;
    },
    send: function() {
      if (this.validateForm)
        this.$store.dispatch("mail/SEND_WORK_FORM", {
          fio: this.fio,
          vacancy: this.vacancy,
          phone: this.phone,
          email: this.email,
          text: this.text,
          files: this.files
        });
      else this.$store.dispatch("mail/SHOW_WORK_FORM_ERROR");
    }
  },
  computed: {
    validateForm: function() {
      if (!this.fio || !(this.fio.length < 50)) return false;
      if (!this.vacancy || !(this.vacancy.length < 50)) return false;
      if (!this.email || !/.+@.+\..+/.test(this.email)) return false;
      return true;
    },
    dialog: {
      get() {
        return this.$store.state["mail"]["WorkFormLoad"];
      },
      set() {
        this.fio = "";
        this.vacancy = "";
        this.phone = "";
        this.email = "";
        this.text = "";
        this.files = [];
        this.$store.dispatch("mail/HIDE_FORM", "WorkFormLoad");
      }
    },
    dialog_error: {
      get() {
        return this.$store.state["mail"]["WorkFormError"];
      },
      set() {
        this.$store.dispatch("mail/HIDE_FORM", "WorkFormError");
      }
    }
  },
  data() {
    return {
      valid: true,
      fio: "",
      vacancy: "",
      text: "",
      files: [],
      nameRules: [
        v => !!v || "Обязательное поле",
        v =>
          (v && v.length <= 50) || "Имя должно содержать не более 50 символов"
      ],
      email: "",
      emailRules: [
        v => !!v || "Обязательное поле",
        v =>
          /.+@.+\..+/.test(v) || "Электронная почта должна быть действительной"
      ],
      phone: ""
    };
  }
};
</script>

<style scoped></style>
