<template>
  <v-app>
    <div id="nav">
      <ToolBar style="position:relative;z-index: 21" />
      <router-view />
      <StickyToolBar :offsetTop="offsetTop" />
<!--      <button-up :offsetTop="offsetTop"></button-up>-->
      <Footer></Footer>
    </div>
  </v-app>
</template>

<script>
import "./assets/sass/app.scss";
import ToolBar from "./components/ui/ToolBar";
import Footer from "./components/ui/Footer";
// import ButtonUp from "./components/ui/ButtonUp";
import StickyToolBar from "@/components/ui/StickyToolBar";
export default {
  name: "App",
  components: { StickyToolBar, ToolBar, Footer },

  data: () => {
    return {
      offsetTop: 0
      //
    };
  },
  mounted() {
    this.$loading = this.$refs.loading;
    var vm = this;
    window.addEventListener("scroll", function() {
      var scrollPos = window.scrollY;
      var winHeight = window.innerHeight;
      var perc = (100 * scrollPos) / (1500 - winHeight);
      vm.offsetTop = perc;
    });
    const favicon = document.getElementById("favicon");
    favicon.href =
      "http://" + window.location.href.split("/")[2] + "/logoITMosaik.svg";
  }
};
</script>
