<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="contacts-row no-indentation px-lg-12 px-md-0">
      <v-col cols="12" lg="4" md="4" sm="12">
        <h1 class="contentHeading mt-0">Контакты</h1>
        <h1 class="contentSubTitle mt-0">Центральный офис</h1>
        <div class="contentText">
          Россия, Смоленская область, Смоленский район, с. Михновка, ул.
          Солнечная, 1, оф.1<br />
          Телефон:
          <a style="text-decoration: none;" href="tel:+79107879103"
            >+7(910) 787-91-03</a
          >
        </div>
        <div class="contentText">
          Будем рады Вашим отзывам и предложениям! <br />
          Напишите нам:
          <a href="mailto:it-mosaic@yandex.ru" target="_blank"
            ><span style="word-break: unset">it-mosaic@yandex.ru</span></a
          >
        </div>
      </v-col>
      <v-col cols="12" lg="8" md="8" sm="12">
        <div class="partnershipAdvantage pa-0">
          <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3Ada161c91bba9784e0ac6b3e06acc0ff8ba81547dc5bf7030ca070132f647098b&amp;source=constructor"
            width="1140"
            height="620"
            frameborder="0"
          ></iframe>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name:"ourContacts",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Контакты";
      }
    }
  }
};
</script>
