<template>
  <div>
    <MainSliderNew style="position: relative; z-index: 999"></MainSliderNew>

    <div class="parentInfoScreen">
      <div class="infoScreen">
        <h1>ИТ-Мозаика</h1>
        <h3>Разработка комплексных IT-решений</h3>
        <div class="infoBlocks offset-sm-0">
          <InfoBlock
            img="web-design"
            title="Дизайн"
            content="Индивидуальный подход. Стильный дизайн привлечет внимание, удобный интерфейс
 обеспечит легкий доступ к информации."
          />
          <InfoBlock
            img="pop-up"
            title="Разработка"
            content="Мы не создаем шаблонные решения.
Мы разрабатываем решение под задачи клиентов."
          />
          <InfoBlock
            img="setting"
            title="Поддержка"
            content="Своевременная техническая поддержка проекта, а также его совершенствование по мере
развития Вашего бизнеса."
          />
        </div>
      </div>
    </div>
    <div class="parentOurProjects">
      <div class="ourProjects">
        <h1>Наши проекты</h1>
        <div class="projectBlocks">
          <ProjectCard
            v-for="card in our_project"
            v-bind:key="card.id"
            :img="card.image"
            :title="card.short_name"
            :content="card.category"
          />
        </div>
        <v-btn
          @click="pushPortfolio(0)"
          class="btnAllProject"
          x-large
          dark
          rounded
        >
          Все проекты
        </v-btn>
      </div>
    </div>
    <div class="parentOurClients">
      <div class="ourClients">
        <h1>Наши клиенты</h1>
        <div class="photoClients">
          <v-img src="/img/logo/logo22.png" contain></v-img>
          <v-img src="/img/logo/somiac.png" contain></v-img>
          <v-img src="/img/logo/gorod_apteka.jpeg" contain></v-img>
          <v-img src="/img/logo/logo_TII.png" contain></v-img>
          <v-img src="/img/logo/logo_SMP.png" contain></v-img>
        </div>
      </div>
    </div>
    <div class="newsFeed">
      <h1 class="titleNewsFeed">Лента</h1>
      <div class="newsCards">
        <div class="noNews" v-if="news_card.length == 0">Новостей пока нет</div>
        <NewsBlock
          v-else
          class="newsMain"
          v-for="current_news in news_card"
          v-bind:key="current_news.id"
          :src="current_news.url_image"
          :id="current_news.id"
          :date="current_news.date"
          :title="current_news.title"
          :content="current_news.text_news"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoBlock from "../components/ui/InfoBlock";
import MainSliderNew from "../components/ui/MainSliderNew";
import ProjectCard from "../components/ui/ProjectCard";
import NewsBlock from "../components/ui/NewsBlock";
export default {
  name: "MainVue",
  components: { MainSliderNew, InfoBlock, ProjectCard, NewsBlock },
  data() {
    this.$store.dispatch("portfolio/GET_PORTFOLIO");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_CATEGORIES");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_IMAGE");
    this.$store.dispatch("news/GET_NEWS");
    this.$store.dispatch("news/GET_NEWS_IMAGE");
    return {};
  },
  computed: {
    our_project() {
      return this.$store.getters["portfolio/portfolio_card_all_categories"];
    },
    news_card() {
      return this.$store.getters["news/news_card"];
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        console.log(to);
        console.log(from);
        document.title = to.meta.title || "ИТ-мозаика";
      },
    },
  },
  methods: {
    url_img(get) {
      "use strict";
      var start_url = window.location.href.split("/")[2];
      if (get === undefined) {
        return (
          "http://" +
          start_url +
          "/api/load_portfolio_image?url=media/placeholder.png"
        );
        // return "http://127.0.0.1:5001/api/load_portfolio_image?url=media/placeholder.png";
      } else
        return (
          "http://" +
          start_url +
          "/api/load_portfolio_image?url=" +
          get.url_image
        );
      // return (
      //   "http://127.0.0.1:5001/api/load_portfolio_image?url=" + get.url_image
      // );
    },
    pushPortfolio(value) {
      this.$router.push("/portfolio/" + value).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
