import * as helper from "@/store/helpers";

// state
export const state = {
  portfolio: [],
  portfolio_categories: [],
  portfolio_works: [],
  portfolio_work_con: [],
  portfolio_images: []
};

// getters
export const getters = {
  portfolio: state => state.portfolio,
  portfolio_categories: state => state.portfolio_categories,
  portfolio_works: state => state.portfolio_works,
  portfolio_images: state => state.portfolio_images,
  portfolio_work_con: state => state.portfolio_work_con,

  portfolio_card_all_categories: state => {
    let cards = [];
    for (let portfolio of state.portfolio) {
      let category = state.portfolio_categories.filter(
        cat => cat.id == portfolio.id_category
      )[0];
      let image = state.portfolio_images.filter(
        img => img.id_portfolio == portfolio.id && img.type == "xl"
      );
      let card = {};
      card.id = portfolio.id;
      card.short_name = portfolio.short_name;
      if (category !== undefined) {
        card.category = category.name;
      }
      card.image =
        image.length > 0 ? image[0].url_image : "media/placeholder.png";
      cards.push(card);
    }
    return cards;
  },

  portfolio_card_by_id_category: state => id_category => {
    let cards = [];
    for (let portfolio of state.portfolio.filter(
      port => port.id_category == id_category
    )) {
      let category = state.portfolio_categories.filter(
        cat => cat.id == portfolio.id_category
      )[0];
      let image = state.portfolio_images.filter(
        image => image.type == "xl" && image.id_portfolio == portfolio.id
      );
      let card = {};
      card.id = portfolio.id;
      card.short_name = portfolio.short_name;
      card.category = category.name;
      card.image =
        image.length > 0 ? image[0].url_image : "media/placeholder.png";
      cards.push(card);
    }
    return cards;
  },

  portfolio_by_id: state => id => {
    return state.portfolio.filter(portfol => portfol.id == id)[0];
  },

  portfolio_category_by_id_portfolio: state => id => {
    let portfolio = state.portfolio.filter(portfol => portfol.id == id)[0];
    let category = state.portfolio_categories.filter(
      cat => cat.id == portfolio.id_category
    )[0];
    return category;
  },

  portfolio_page: state => title => {
    let portfolio =
      state.portfolio.length === 0
        ? { id: 0, task: "", short_name: "", name: "", id_category: 0 }
        : state.portfolio.filter(portfol => portfol.short_name === title)[0];
    let category =
      state.portfolio_categories.length === 0
        ? { id: 0, name: "" }
        : state.portfolio_categories.filter(
            cat => cat.id === portfolio.id_category
          )[0];
    let image_xl =
      state.portfolio_images.length === 0
        ? { id: 0, type: "", url_image: "", id_portfolio: 0 }
        : state.portfolio_images.filter(
            img => img.id_portfolio === portfolio.id && img.type === "xl"
          );
    let image_sm =
      state.portfolio_images.length === 0
        ? [{ id: 0, type: "", url_image: "", id_portfolio: 0 }]
        : state.portfolio_images.filter(
            img => img.id_portfolio === portfolio.id && img.type === "sm"
          );
    let image_md =
      state.portfolio_images.length === 0
        ? [{ id: 0, type: "", url_image: "", id_portfolio: 0 }]
        : state.portfolio_images.filter(
            img => img.id_portfolio === portfolio.id && img.type === "md"
          );
    let image_lg =
      state.portfolio_images.length === 0
        ? [{ id: 0, type: "", url_image: "", id_portfolio: 0 }]
        : state.portfolio_images.filter(
            img => img.id_portfolio === portfolio.id && img.type === "lg"
          );
    let portfolio_work_con =
      state.portfolio_work_con == undefined
        ? [{ id: 0, id_portfolio: 0, id_portfolio_work: 0 }]
        : state.portfolio_work_con.filter(
            con => con.id_portfolio === portfolio.id
          );
    let work = "";
    portfolio_work_con.forEach(work_con => {
      if (
        state.portfolio_works.filter(
          wrk => wrk.id === work_con.id_portfolio_work
        )[0] !== undefined
      ) {
        work +=
          state.portfolio_works.filter(
            wrk => wrk.id === work_con.id_portfolio_work
          )[0].name_work + ", ";
      }
    });
    if (work.length !== 0) work = work.substring(0, work.length - 2) + ".";
    if (
      portfolio !== undefined &&
      category !== undefined &&
      image_xl !== undefined &&
      image_sm !== undefined &&
      image_md !== undefined &&
      image_lg !== undefined
    ) {
      let page_data = {};
      page_data.name = portfolio.name;
      page_data.task = portfolio.task;
      page_data.category = category.name;
      page_data.image_xl = image_xl[0];
      page_data.image_sm = image_sm[0];
      page_data.image_md = image_md[0];
      page_data.image_lg = image_lg[0];
      page_data.work = work;
      page_data.work = work;
      return page_data;
    }
  },

  portfolio_iphone: state => title => {
    let portfolio =
      state.portfolio.length === 0
        ? { id: 0, task: "", short_name: "", name: "", id_category: 0 }
        : state.portfolio.filter(portfol => portfol.short_name === title)[0];
    let image_sm =
      state.portfolio_images.length === 0
        ? { id: 0, type: "", url_image: "", id_portfolio: 0 }
        : state.portfolio_images.filter(
            img => img.id_portfolio === portfolio.id && img.type === "sm"
          );
    if (portfolio !== undefined && image_sm !== undefined) {
      if (image_sm.length > 0) {
        return image_sm;
      } else return [{ url_image: "media/placeholder.png" }];
    }
  },
  portfolio_slider: state => title => {
    let portfolio =
      state.portfolio.length === 0
        ? { id: 0, task: "", short_name: "", name: "", id_category: 0 }
        : state.portfolio.filter(portfol => portfol.short_name === title)[0];
    let image_xl =
      state.portfolio_images.length === 0
        ? { id: 0, type: "", url_image: "", id_portfolio: 0 }
        : state.portfolio_images.filter(
            img => img.id_portfolio === portfolio.id && img.type === "xl"
          );
    if (portfolio !== undefined && image_xl !== undefined) {
      if (image_xl.length > 0) {
        return image_xl;
      } else return [{ url_image: "media/placeholder.png" }];
    }
  }
};

// mutations
export const mutations = {
  SET_PORTFOLIO(state, portfolio) {
    state.portfolio = portfolio;
  },
  SET_PORTFOLIO_CATEGORIES(state, portfolio_categories) {
    state.portfolio_categories = portfolio_categories;
  },
  SET_PORTFOLIO_IMAGE(state, portfolio_images) {
    state.portfolio_images = portfolio_images;
  },
  SET_PORTFOLIO_WORK(state, portfolio_works) {
    state.portfolio_works = portfolio_works;
  },
  SET_PORTFOLIO_WORK_CON(state, portfolio_work_con) {
    state.portfolio_work_con = portfolio_work_con;
  }
};

// actions
export const actions = {
  GET_PORTFOLIO: async function({ commit }) {
    function Hello(data) {
      commit("SET_PORTFOLIO", data["response"]);
    }
    helper.api("portfolio", "", Hello);
  },
  GET_PORTFOLIO_CATEGORIES: async function({ commit }) {
    function Hello(data) {
      commit("SET_PORTFOLIO_CATEGORIES", data["response"]);
    }
    helper.api("portfolio_category", "", Hello);
  },
  GET_PORTFOLIO_IMAGE: async function({ commit }) {
    function Hello(data) {
      commit("SET_PORTFOLIO_IMAGE", data["response"]);
    }
    helper.api("portfolio_image", "", Hello);
  },
  GET_PORTFOLIO_WORK: async function({ commit }) {
    function Hello(data) {
      commit("SET_PORTFOLIO_WORK", data["response"]);
    }
    helper.api("portfolio_work", "", Hello);
  },
  GET_PORTFOLIO_WORK_CON: async function({ commit }) {
    function Hello(data) {
      commit("SET_PORTFOLIO_WORK_CON", data["response"]);
    }
    helper.api("portfolio_work_con", "", Hello);
  }
};
