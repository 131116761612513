<template>
  <div class="menu">
    <v-navigation-drawer
      v-model="menu"
      location="left"
      temporary
      :width="windowWidth * 0.85"
    >
      <v-list class="menuColor btnCloseMenu">
        <v-list-item>
          <v-btn
            class="taskFormClose"
            color="primary"
            variant="text"
            rounded
            style="width: 50px; height: 50px; min-width: 0"
            @click="menu = false"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
      <hr />
      <v-list class="menuColor" style="height: 93vh; width: 40vh !important">
        <v-list-item class="list">
          <v-btn
            v-on:click="showPrices = !showPrices"
            variant="text"
            class="menuBtnMobile"
            block
            color="light-blue darken-2"
            >Цены</v-btn
          >
        </v-list-item>
        <transition name="fade">
          <v-list class="subMenuPrice offset-1" v-if="showPrices">
            <v-list-item
              v-for="price in categories"
              v-bind:key="price.id"
              class="list"
            >
              <v-btn
                variant="text"
                @click="pushPrices(price.id)"
                class="menuBtnMobile text-truncate"
                block
                color="light-blue darken-2"
                style="width: 90%"
                title="Сайт на конструкторе Tilda"
                >{{ price.name }}</v-btn
              >
            </v-list-item>
          </v-list>
        </transition>
        <v-list-item class="list">
          <v-btn
            v-on:click="showPortfolio = !showPortfolio"
            variant="text"
            class="menuBtnMobile"
            block
            color="light-blue darken-2"
            >Портфолио</v-btn
          >
        </v-list-item>
        <transition name="fade">
          <v-list class="subMenuPrice offset-1" v-if="showPortfolio">
            <v-list-item
              v-for="cat in portfolio_cards"
              v-bind:key="cat.id"
              class="list"
            >
              <v-btn
                variant="text"
                @click="pushPortfolio(cat.id)"
                class="menuBtnMobile text-truncate"
                block
                color="light-blue darken-2"
                style="width: 90%"
                >{{ cat.name }}</v-btn
              >
            </v-list-item>
          </v-list>
        </transition>
        <v-list-item class="list">
          <v-btn
            variant="text"
            @click="pushAboutCompany()"
            class="menuBtnMobile"
            block
            color="light-blue darken-2"
            >О компании</v-btn
          >
        </v-list-item>
        <v-list-item class="list">
          <v-btn
            variant="text"
            @click="pushNewsFeed()"
            class="menuBtnMobile"
            block
            color="light-blue darken-2"
            >Лента</v-btn
          >
        </v-list-item>
        <v-list-item class="list">
          <v-btn
            variant="text"
            @click="pushCareer()"
            class="menuBtnMobile"
            block
            color="light-blue darken-2"
            >Карьера</v-btn
          >
        </v-list-item>
        <v-list-item class="list">
          <v-btn
            variant="text"
            @click="pushContacts()"
            class="menuBtnMobile"
            block
            color="light-blue darken-2"
            >Контакты</v-btn
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar class="menu-toolbar">
      <v-row>
        <v-col cols="2" class="burgerMenu">
          <div>
            <v-btn
              size="x-large"
              icon
              @click.stop="menu = true"
              class="mobileMenu"
            >
              <v-icon size="x-large" color="#fff">mdi-menu</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="8" md="6" class="logo">
          <div class="logoSvg pa-0">
            <div>
              <a @click="pushMain()"
                ><img
                  src="/img/icons/logoITMosaik.svg"
                  style="
                    width: 100%;
                    height: 96px;
                    min-width: 110px;
                    filter: invert(0.9);
                  "
              /></a>
            </div>
          </div>
          <div class="logoText pa-0">
            <h1>ИТ-Мозаика</h1>

            <h2>разработка комплексных IT-решений</h2>
          </div>
        </v-col>

        <v-col cols="8" class="logoMobile">
          <div>
            <a @click="pushMain()"
              ><img src="/img/icons/logoITMosaik.svg" style="height: 104px"
            /></a>
          </div>
        </v-col>
        <v-col class="colFeedBack" cols="6" style="font-size: 16px">
          <div class="menuFeedback">
            <div>
              <strong class="number">+7(910) 787-91-03</strong>
              <OpenDialogButton />
              <FeedbackDialog />
            </div>
          </div>
        </v-col>
        <v-col class="colFeedBackMobile" cols="2" style="font-size: 16px">
          <OpenDialogButton />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" offset="1" sm="12" offset-sm="0" class="menu_content">
          <v-toolbar-items class="no-indentation">
            <v-container fill-height fluid>
              <v-row align="center" justify="center" no-gutters>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-2"
                  @click="pushPrices(1)"
                >
                  <h4 class="menu_content_text">Цены</h4>
                </v-btn>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-2"
                  @click="pushPortfolio(0)"
                >
                  <h4 class="menu_content_text">Портфолио</h4>
                </v-btn>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-2"
                  @click="pushAboutCompany()"
                >
                  <h4 class="menu_content_text">О компании</h4>
                </v-btn>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-2"
                  @click="pushNewsFeed()"
                >
                  <h4 class="menu_content_text">Лента</h4>
                </v-btn>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-2"
                  @click="pushCareer()"
                >
                  <h4 class="menu_content_text">Карьера</h4>
                </v-btn>
                <v-btn
                  variant="text"
                  rounded
                  class="menu_content_text-background my-2 mx-2"
                  @click="pushContacts()"
                >
                  <h4 class="menu_content_text">Контакты</h4>
                </v-btn>
              </v-row>
            </v-container>
          </v-toolbar-items>
        </v-col>
      </v-row>
    </v-toolbar>
  </div>
</template>

<script>
import FeedbackDialog from "@/components/ui/FeedbackDialog.vue";
import OpenDialogButton from "@/components/ui/OpenDialogButton.vue";
export default {
  name: "ToolBar.vue",
  components: {
    OpenDialogButton,
    FeedbackDialog,
  },
  data() {
    this.$store.dispatch("portfolio/GET_PORTFOLIO");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_CATEGORIES");
    this.$store.dispatch("prices/GET_CATEGORIES");
    this.$store.dispatch("prices/GET_PRICES");
    return {
      showRequestForm: false,
      showPrices: false,
      showPortfolio: false,
      closeOnContentClick: false,
      menu: false,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    pushMain() {
      this.$router.push("/about").catch(() => {});
    },
    pushContacts() {
      this.$router.push("/contacts").catch(() => {});
    },
    pushCareer() {
      this.$router.push("/career").catch(() => {});
    },
    pushAboutCompany() {
      this.$router.push("/about").catch(() => {});
    },
    pushNewsFeed() {
      this.$router.push("/newsfeed").catch(() => {});
    },
    pushPrices(value) {
      this.$router.push("/prices/" + value).catch(() => {});
    },
    pushPortfolio(value) {
      this.$router.push("/portfolio/" + value).catch(() => {});
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    categories() {
      return this.$store.getters["prices/categories"];
    },
    portfolio_cards() {
      return this.$store.getters["portfolio/portfolio_categories"];
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped></style>
