<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-row class="mx-n3 my-0">
        <v-col cols="12">
          <v-text-field
            v-model="name"
            :counter="50"
            :rules="nameRules"
            label="Имя"
            prepend-inner-icon="mdi-map-marker"
            class="taskFormCardInputStyle"
            required
            variant="outlined"
          />
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Email"
            prepend-inner-icon="mdi-map-marker"
            class="taskFormCardInputStyle"
            required
            variant="outlined"
          />
          <v-text-field
            v-model="phone"
            label="Телефон"
            prepend-inner-icon="mdi-map-marker"
            class="taskFormCardInputStyle"
            required
            variant="outlined"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="message"
            label="Опишите задачу"
            prepend-inner-icon="mdi-map-marker"
            class="taskFormCardInputStyle"
            required
            variant="outlined"
            auto-grow
            row-height="26"
          />
          <v-btn
            size="x-large"
            class="btn_feedback mx-auto d-flex"
            elevation="2"
            variant="outlined"
            rounded
            v-on:click="send()"
            ><span class="span_btn_feedback">Отправить заявку</span>
          </v-btn>
          <v-dialog v-model="dialog" width="300">
            <v-card>
              <v-card-text class="pt-6 pb-8 circle-check">
                <i class="fas fa-check check-icon"></i>
                <p>Отправлено</p>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog_error" width="300">
            <v-card>
              <v-card-text class="pt-8 pb-8 circle-check">
                <i class="fas fa-times-circle cansel-icon"></i>
                <p>Вы неправильно заполнили форму</p>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
export default {
  name: "TaskFormToolBar",
  methods: {
    send: function() {
      if (this.validateForm) {
        this.$store.dispatch("mail/SEND_TASK_FORM", {
          name: this.name,
          email: this.email,
          phone: this.phone,
          message: this.message
        });
      } else this.$store.dispatch("mail/SHOW_TASK_FORM_ERROR");
    }
  },
  computed: {
    validateForm: function() {
      if (!this.name || !(this.name.length < 50)) return false;
      if (!this.email || !/.+@.+\..+/.test(this.email)) return false;
      return true;
    },
    dialog: {
      get() {
        return this.$store.state["mail"]["taskFormLoad"];
      },
      set() {
        this.name = "";
        this.email = "";
        this.phone = "";
        this.message = "";
        this.$store.dispatch("mail/HIDE_FORM", "taskFormLoad");
      }
    },
    dialog_error: {
      get() {
        return this.$store.state["mail"]["taskFormError"];
      },
      set() {
        this.$store.dispatch("mail/HIDE_FORM", "taskFormError");
      }
    }
  },
  data() {
    return {
      valid: true,
      name: "",
      email: "",
      message: "",
      nameRules: [
        v => !!v || "Обязательное поле",
        v =>
          (v && v.length <= 50) || "Имя должно содержать не более 50 символов"
      ],
      emailRules: [
        v => !!v || "Обязательное поле",
        v =>
          /.+@.+\..+/.test(v) || "Электронная почта должна быть действительной"
      ],
      phone: ""
    };
  }
};
</script>
