<template>
  <v-row class="no-indentation contentPrice">
    <v-col cols="5" class="py-0 px-3">
      <div class="contentPriceHeading">{{ name }}</div>
    </v-col>
    <v-col cols="2" offset="1" class="no-indentation px-3">
      <div class="contentPriceHeading">{{ price }}</div>
    </v-col>
    <v-col cols="3" offset="1" class="py-0 px-3">
      <div class="contentPriceHeading">{{ time }}</div>
    </v-col>
    <v-col cols="10" md="5" sm="10" class="py-0">
      <div class="contentPriceText mt-6">
        <p v-if="urDescription.length == 1">{{ description }}</p>
        <ul
          style="padding-left: 0;"
          v-else-if="urDescription.length > 1"
          v-for="content in urDescription" v-bind:key="content.id"
        >
          <li style="list-style-type:none">{{ content }}</li>
        </ul>
      </div>
      <div v-if="role != null" class="contentPriceText">
        <span style="font-weight: bold; margin-top: 10px">Ваша роль:</span> <br />
        <p v-if="urRole.length == 1">{{ role }}</p>
        <ul v-else-if="urRole.length > 1" style="padding-left: 24px;">
          <li v-for="content in urRole" v-bind:key="content.id">{{ content }}</li>
        </ul>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "ContentPrice",
  data() {
    return {
      urRole: [],
      urDescription: []
    };
  },
  props: {
    name: String,
    description: String,
    role: String,
    price: String,
    time: String
  },
  mounted() {
    if (this.role != null) {
      if (this.description != null) {
        this.$nextTick(function() {
          this.urRole = this.role.split("\\n");
          this.urDescription = this.description.split("\\n");
        });
      } else {
        this.$nextTick(function() {
          this.urRole = this.role.split("\\n");
        });
      }
    } else if (this.description != null) {
      console.log(this.role);
      console.log(this.description);
      this.$nextTick(function() {
        this.urDescription = this.description.split("\\n");
        console.log(this.urDescription);
      });
    }
  }
};
</script>
