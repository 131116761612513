export default [
  {
    path: "/",
    name: "Main",
    component: require("../views/Main.vue").default,
    meta: { title: "Главная" }
  },
  {
    path: "/news_page/:id",
    name: "NewsPageParam",
    component: require("../views/NewsPage.vue").default,
    meta: { title: "Новости" }
  },
  {
    path: "/portfolio_page/:title",
    name: "PortfolioPageParam",
    component: require("../views/PortfolioPage.vue").default,
    meta: { title: "Портфолио" }
  },
  {
    path: "/prices/:id",
    name: "PricesParam",
    component: require("../views/Prices.vue").default,
    meta: { title: "Цены" }
  },
  {
    path: "/portfolio/:id",
    name: "PortfolioParam",
    component: require("../views/Portfolio.vue").default,
    meta: { title: "Портфолио" }
  }
];
