<template>
  <v-container v-if="news" class="no-indentation">
    <v-row class="newsContent">
      <v-col cols="12" class="py-0">
        <div class="newsPageContent">
          <v-btn @click="backToNewsFeed()" rounded text class="ml-n6">
            <div class="newsPageNewsFeedText">
              <v-icon class="newsPageIconClickable">
                mdi-arrow-left
              </v-icon>
              Лента
            </div>
          </v-btn>
          <div class="newsPageHeading">{{ news.title }}</div>
          <div class="newsPageDate">{{ news.date }}</div>
          <div class="newsPageText">{{ news.text_news }}</div>
        </div>
      </v-col>
      <v-col cols="6">
        <div
          v-if="InstagramURL != '' || VkURL != ''"
          class="newsPageIcons pt-2 ml-n4"
        >
          <v-icon
            v-if="InstagramURL != ''"
            @click="OpenIntagram"
            class="newsPageIconSocialNetwork newsPageIconClickable ml-4"
          >
            mdi-instagram
          </v-icon>
          <v-icon
            v-if="VkURL != ''"
            @click="OpenVK"
            class="newsPageIconSocialNetwork newsPageIconClickable ml-4"
          >
            fab fa-vk
          </v-icon>
        </div>
        <div class="newsPageBtnPortfolio">
          <v-btn
            v-if="news.id_portfolio"
            block
            rounded
            class="px-sm-4 px-8 py-8"
            @click="pushPortfolio(news.id_portfolio)"
          >
            <div class="newsPageBtnPortfolioTextBr">
              Ознакомиться <br />с работой
            </div>
            <div class="newsPageBtnPortfolioText">
              Ознакомиться с работой
            </div>
          </v-btn>
        </div>
      </v-col>
      <v-col class="newsPageButtons" cols="6">
        <div class="newsPageButtons">
          <v-btn
            @click="PrevNews()"
            text
            rounded
            class="ml-sm-n3 ml-lg-6 px-2 py-8"
          >
            <div class="newsPageBack">
              <v-icon class="newsPageIconClickable ArrowLeft">
                mdi-arrow-left
              </v-icon>
              <br />
              Пред.
            </div>
          </v-btn>
          <v-btn @click="NextNews()" text rounded class="mr-sm-n3 px-2 py-8">
            <div class="newsPageBack">
              <v-icon class="newsPageIconClickable ArrowRight">
                mdi-arrow-right
              </v-icon>
              <br />
              След.
            </div>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NewsContent",
  props: ["id"],
  data() {
    this.$store.dispatch("news/GET_NEWS");
    this.$store.dispatch("news/GET_NEWS_IMAGE");
    return {
      InstagramURL: "#",
      VkURL: "#"
    };
  },
  computed: {
    news() {
      return this.$store.getters["news/news"].filter(
        news => news.id == this.id
      )[0];
    }
  },
  methods: {
    OpenIntagram() {
      window.open(this.$data.InstagramURL);
    },
    OpenVK() {
      window.open(this.$data.VkURL);
    },
    NextNews() {
      let arr_news = this.$store.getters["news/news"];
      let id_curr_news = Number(
        this.$store.getters["news/news"].filter(news => news.id == this.id)[0]
          .id
      );
      let idx_curr_news = arr_news.findIndex(el => {
        return el.id === id_curr_news;
      });
      let next_el = arr_news[idx_curr_news + 1];
      next_el
        ? this.$router.push("/news_page/" + next_el.id).catch(() => {})
        : this.$router.push("/news_page/" + arr_news[0].id).catch(() => {});
    },
    PrevNews() {
      let arr_news = this.$store.getters["news/news"];
      let id_curr_news = Number(
        this.$store.getters["news/news"].filter(news => news.id == this.id)[0]
          .id
      );
      let idx_curr_news = arr_news.findIndex(el => {
        return el.id === id_curr_news;
      });
      let prev_el = arr_news[idx_curr_news - 1];
      prev_el
        ? this.$router.push("/news_page/" + prev_el.id).catch(() => {})
        : this.$router
            .push("/news_page/" + arr_news[arr_news.length - 1].id)
            .catch(() => {});
    },
    backToNewsFeed() {
      this.$router.push("/newsfeed").catch(() => {});
    },
    pushPortfolio(id_portfolio) {
      var port_name = this.$store.getters["portfolio/portfolio_by_id"](
        id_portfolio
      );
      this.$router
        .push("/portfolio_page/" + port_name.short_name)
        .catch(() => {});
    }
  }
};
</script>
