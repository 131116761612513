<script>
import TaskFormCard from "@/components/ui/TaskFormCard.vue";

export default {
  name: "FeedbackDialog" ,
  components: {TaskFormCard},
  methods: {
    closeDialog() {
      this.$store.dispatch('feedbackDialogStore/closeDialog');
    }
  },
  computed: {
    isShow: {
      set() {

      },
      get() {
        // return true
        return this.$store.getters["feedbackDialogStore/isShowDialog"];
      }
    }
  }
}
</script>

<template>
  <v-dialog width="500" v-model="isShow">
    <v-card>
      <v-card-title class="text-h5 taskFormCardHeader">
        Отправить заявку
        <div class="flex-grow-1"></div>
        <v-btn
            class="taskFormClose"
            color="primary"
            variant="text"
            rounded
            style="width: 50px; height: 50px; min-width: 0"
            @click="closeDialog"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="taskFormCardText">
        <TaskFormCard></TaskFormCard>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>