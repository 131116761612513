<template>
  <div class="sliderIphone">
    <swiper
        :options="swiperOption"
        :modules="modules"
        navigation
        :pagination="{ clickable: true }"
        class="no-indentation iphoneSliderSlider"
    >
      <div
        class="parallax-bg"

        data-swiper-parallax="-23%"
      ></div>
      <swiper-slide v-for="img in portfolio_iphone" v-bind:key="img.id" class="no-indentation">
        <img
          class="iphoneSliderImage"
          src="/img/iphone_5S.png"
        />
        <img
          v-if="portfolio_iphone.length == 0"
          class="iphoneSliderImageInside"
          src="/img/icons/placeholder.png"
        />
        <img
          v-else
          class="iphoneSliderImageInside"
          :src="getImage(img.url_image)"
        />
      </swiper-slide>
<!--      <div-->
<!--        class="swiper-pagination swiper-pagination-white"-->
<!--      ></div>-->
<!--      <div-->
<!--        class="swiper-button-prev swiper-button-white"-->
<!--      ></div>-->
<!--      <div-->
<!--        class="swiper-button-next swiper-button-white"-->

<!--      ></div>-->
    </swiper>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from "swiper/modules";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export default {
  props: ["title"],
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    this.$store.dispatch("portfolio/GET_PORTFOLIO");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_IMAGE");
    return {
      swiperOption: {
        speed: 1200,
        loop: false,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false
        },
        parallax: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev"
        // }
      },
      modules: [Navigation, Pagination]
    };
  },
  computed: {
    portfolio_iphone() {
      return this.$store.getters["portfolio/portfolio_iphone"](
        this.$route.params.title
      );
    }
  },
  methods: {
    getImage(url) {
      "use strict";
      var start_url = window.location.href.split("/")[2];
      return "http://" + start_url + "/api/load_portfolio_image?url=" + url;
      // return "http://127.0.0.1:5001/api/load_portfolio_image?url=" + url;
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  font-size: 18px;
  color: #000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
  background-color: transparent !important;
  justify-content: space-around !important;
}
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-color: #000000;
  opacity: 0.3;
}
</style>
