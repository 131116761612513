import * as helper from "@/store/helpers";

// state
export const state = {
  taskForm: false,
  taskFormError: false,
  taskFormLoad: false,
  WorkForm: false,
  WorkFormError: false,
  WorkFormLoad: false,
};

// getters
export const getters = {
  taskForm: (state) => state.taskForm,
  taskFormError: (state) => state.taskFormError,
  taskFormLoad: (state) => state.taskFormLoad,
  WorkForm: (state) => state.WorkForm,
  WorkFormError: (state) => state.WorkFormError,
  WorkFormLoad: (state) => state.WorkFormLoad,
};

// mutations
export const mutations = {
  SHOW_LOADER(state, { form, value }) {
    state[form + "Load"] = value;
  },
  SHOW_ERROR(state, { form, value }) {
    state[form + "Error"] = value;
  },
  SHOW_FORM(state, { form, value }) {
    state[form] = value;
  },
};

// actions
export const actions = {
  SEND_TASK_FORM: async function ({ commit }, { name, email, phone, message }) {
    function afterLoad() {
      commit("SHOW_LOADER", { form: "taskForm", value: false });
      commit("SHOW_FORM", { form: "taskForm", value: false });
    }
    commit("SHOW_LOADER", { form: "taskForm", value: true });
    helper.api(
      "send_mail",
      { name: name, email: email, phone: phone, message: message },
      afterLoad
    );
  },
  SEND_WORK_FORM: async function (
    { commit },
    { fio, vacancy, phone, email, text, files }
  ) {
    function afterLoad() {
      commit("SHOW_LOADER", { form: "taskForm", value: false });
      commit("SHOW_FORM", { form: "taskForm", value: false });
    }
    commit("SHOW_LOADER", { form: "WorkForm", value: true });
    helper.api_file(
      "work_form",
      {
        fio: fio,
        vacancy: vacancy,
        email: email,
        phone: phone,
        text: text,
      },
      files,
      afterLoad
    );
  },
  SHOW_TASK_FORM_ERROR: async function ({ commit }) {
    commit("SHOW_ERROR", { form: "taskForm", value: true });
  },
  SHOW_WORK_FORM_ERROR: async function ({ commit }) {
    commit("SHOW_ERROR", { form: "WorkForm", value: true });
  },
  SHOW_FORM: async function ({ commit }, form) {
    commit("SHOW_FORM", { form: form, value: true });
  },
  HIDE_FORM: async function ({ commit }, form) {
    commit("SHOW_FORM", { form: form, value: false });
  },
};
