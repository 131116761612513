<template>
  <div>
    <v-container class="no-indentation" fluid fill-height>
      <v-row class="no-indentation">
        <v-btn-toggle
          v-model="toggle_exclusive"
          style="display: list-item; list-style: none; height: unset"
        >
          <v-btn
            style="border: none;border-radius: 28px;"
            v-for="category in Array_categories"
            text
            rounded
            class="Categories my-2 mx-7 px-4"
            :key="category.id"
            @click="pushCat(category.id)"
            v-on:click="$emit('change_category_id', category.id)"
          >
            <div class="categoryBtnText">{{ category.name }}</div>
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "OurCategories",
  props: ["Array_categories", "id_price_cat", "id_portfolio_cat"],
  data() {
    var cat =null;
    if (this.id_price_cat) {
      console.log(this.id_price_cat);
      cat = Number(this.id_price_cat) - 1;
    } else if (this.id_portfolio_cat) {
      cat = Number(this.id_portfolio_cat);
    }
    return {
      toggle_exclusive: cat
    };
  },
  updated() {
    this.pushCat(window.location.href.split("/")[4]);
  },
  methods: {
    pushCat(id) {
      var str = window.location.href.split("/")[3];
      this.$router.push("/" + str + "/" + id).catch(() => {});
    }
  }
};
</script>
