

export function api(path, data, func) {
  // var startUrl = "http://0.0.0.0:8000";
  var startUrl = "";
  if (process.env.VUE_APP_URL !== undefined) {
    startUrl = process.env.VUE_APP_URL;
  }
  fetch(startUrl + `/api/` + path, {
    method: "POST",
    headers: {
      "access-control-allow-origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Content-type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Headers": "content-type"
    },
    body: JSON.stringify(data)
  }).then(res => {
    res.json().then(json => {
      func(json);
    });
  });
}

export function api_file(path, data, files, func) {
  // var startUrl = "http://0.0.0.0:8000";
  var startUrl = "";
  if (process.env.VUE_APP_URL !== undefined) {
    startUrl = process.env.VUE_APP_URL;
  }
  console.log(startUrl);
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  for (var i = 0; i < files.length; i++) {
    formData.append(files[i].name, files[i], files[i].name);
  }

  fetch(startUrl + `/api/` + path, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Content-type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Headers": "content-type"
    },
    body: formData
  }).then(res => {
    res.json().then(json => {
      func(json);
    });
  });
}

export function getData(data) {
  return { auth: { token: '' }, data: data };
}

export default { api, api_file, getData };
