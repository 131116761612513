<template>
  <div class="sliderPortfolio">
    <Swiper
      :options="swiperOption"
      :modules="modules"
      navigation
      :pagination="{ clickable: true }"
      class="no-indentation portfolioSliderSlider"
    >
      <div
        class="parallax-bg"
        data-swiper-parallax="-23%"
      ></div>
      <SwiperSlide v-for="img in portfolio_slider" v-bind:key="img.id" class="no-indentation">
        <v-img
          v-if="portfolio_slider.length == 0"
          contain
          class="portfolioSliderImage"
          src="/img/icons/placeholder.png"
        />
        <v-img
          v-else
          contain
          class="portfolioSliderImage"
          :src="getImage(img.url_image)"
        />
      </SwiperSlide>
      <!--      <swiper-slide><v-img src="http://127.0.0.1:5001/api/load_portfolio_image?url=media/portfolio/Скриншот сделанный 2022-01-17 в 10.49.17.png"></v-img></swiper-slide>-->
      <!--      <swiper-slide><v-img src="http://127.0.0.1:5001/api/load_portfolio_image?url=media/portfolio/Скриншот сделанный 2022-01-17 в 10.49.17.png"></v-img></swiper-slide>-->
      <!--      <swiper-slide><v-img src="http://127.0.0.1:5001/api/load_portfolio_image?url=media/portfolio/Скриншот сделанный 2022-01-17 в 10.49.17.png"></v-img></swiper-slide>-->
      <!--      <swiper-slide><v-img src="http://127.0.0.1:5001/api/load_portfolio_image?url=media/portfolio/Скриншот сделанный 2022-01-17 в 10.49.17.png"></v-img></swiper-slide>-->
<!--      <div-->
<!--        class="swiper-pagination swiper-pagination-white"-->
<!--      ></div>-->
<!--      <div-->
<!--          id="portfolio-slider-prev-btn"-->
<!--        class="swiper-button-prev swiper-button-black"-->
<!--      ></div>-->
<!--      <div-->
<!--          id="portfolio-slider-next-btn"-->
<!--        class="swiper-button-next swiper-button-black"-->
<!--      ></div>-->
    </Swiper>
  </div>
</template>

<script>
import "swiper/css";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from "swiper/modules";
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    this.$store.dispatch("portfolio/GET_PORTFOLIO");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_IMAGE");
    return {
      swiperOption: {
        speed: 1200,
        loop: false,
        autoplay: {
          delay: 700000,
          disableOnInteraction: false
        },
        parallax: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // },
        // navigation: {
        //   nextEl: "portfolio-slider-next-btn",
        //   prevEl: "portfolio-slider-prev-btn"
        // }
      },
      modules: [Navigation, Pagination]
    };
  },
  computed: {
    portfolio_slider() {
      return this.$store.getters["portfolio/portfolio_slider"](
        this.$route.params.title
      );
    }
  },
  methods: {
    getImage(url) {
      "use strict";
      var start_url = window.location.href.split("/")[2];
      return "http://" + start_url + "/api/load_portfolio_image?url=" + url;
      // return "http://127.0.0.1:5001/api/load_portfolio_image?url=" + url;
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  font-size: 18px;
  color: #000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px !important;
  background-color: transparent !important;
  justify-content: space-around !important;
}
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-color: #000000;
  opacity: 0.3;
}
</style>
