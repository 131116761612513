<template>
  <v-row class="no-indentation contentPriceMobile">
    <v-col offset="1" cols="10">
      <div class="contentPriceMobileHeading">{{ name }}</div>
      <div class="contentPriceMobileText mt-3">{{ description }}</div>
      <div class="contentPriceMobileText">
        <span style="font-weight: bold">Ваша роль:</span> <br />{{ role }}
      </div>
      <div class="contentPriceMobileHeading mt-3">
        Срок разработки: {{ time }}
      </div>
      <div class="contentPriceMobileHeading mt-3">Цена: {{ price }}</div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "ContentPriceMobile",
  props: {
    name: String,
    description: String,
    role: String,
    price: String,
    time: String
  }
};
</script>
