import * as helper from "@/store/helpers";

// state
export const state = {
  news: [],
  news_image: [],
  news_with_anchor: {}
};

// getters
export const getters = {
  news: state => state.news,
  news_image: state => state.news_image,
  news_with_anchor: state => id => {
    return state.news.filter(news => news.id === id)[0];
  },
  news_card: state => {
    let cards = [];
    for (let curr_news of state.news) {
      let image = state.news_image.filter(img => {
        return img.id_news === curr_news.id;
      });
      let card = {};
      card.id = curr_news.id;
      card.title = curr_news.title;
      card.text_news = curr_news.text_news;
      card.date = curr_news.date;
      card.url_image =
        image.length > 0 ? image[0].url_image : "media/placeholder.png";
      cards.push(card);
    }
    return cards;
  }
};

// mutations
export const mutations = {
  SET_NEWS(state, news) {
    state.news = news;
  },
  SET_NEWS_IMAGE(state, news_image) {
    state.news_image = news_image;
  }
};

// actions
export const actions = {
  GET_NEWS: async function({ commit }) {
    function Hello(data) {
      commit("SET_NEWS", data["response"]);
    }
    helper.api("news", "", Hello);
  },
  GET_NEWS_IMAGE: async function({ commit }) {
    function Hello(data) {
      commit("SET_NEWS_IMAGE", data["response"]);
    }
    helper.api("news_image", "", Hello);
  }
};
