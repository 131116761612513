<template>
  <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="portfolioCard pa-2 mb-4">
    <v-row>
      <v-col cols="10" offset="1" lg="10" offset-lg="1">
        <a>
          <v-img
            @click="pushProductPage(title)"
            :src="getImage(img)"
            class="imageCardPortfolio"
          >
          </v-img>
        </a>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" offset="2">
        <v-row>
          <v-col cols="12" class="py-1 px-0">
            <h2 class="titlePortfolioCard">
              <a @click="pushProductPage(title)">{{ title }}</a>
            </h2>
          </v-col>
          <v-col cols="12" class="py-1 px-0">
            <p class="contentPortfolioCard">{{ content }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["id", "img", "title", "content"],
  methods: {
    pushProductPage(value) {
      this.$router.push("/portfolio_page/" + value).catch(() => {});
    },
    getImage(url) {
      "use strict";
      var start_url = window.location.href.split("/")[2];
      return "http://" + start_url + "/api/load_portfolio_image?url=" + url;
      // return "http://127.0.0.1:5001/api/load_portfolio_image?url=" + url;
    }
  },
  name: "PortfolioCard"
};
</script>

<style scoped></style>
