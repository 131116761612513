<template>
  <div>
    <v-row class="portfolio">
      <v-col cols="12" sm="10" class="mx-auto">
        <h1 class="portfolioHeader">Портфолио</h1>
        <v-row>
          <v-col cols="12" sm="10" offset-sm="1">
            <v-row class="subMenuPortfolio">
              <v-col>
                <Categories
                  :id_portfolio_cat="this.$route.params.id"
                  :Array_categories="categories"
                />
              </v-col>
            </v-row> </v-col
          >--
        </v-row>
        <v-row class="portfolioCards mb-16 mt-16">
          <v-col cols="12">
            <v-row class="portfolioCards">
              <PortfolioCard
                v-for="card in portfolio_cards" v-bind:key="card.id"
                :img="card.image"
                :id="card.id"
                :title="card.short_name"
                :content="card.category"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PortfolioCard from "@/components/ui/PortfolioCard";
import Categories from "@/components/ui/Categories";
export default {
  name: "OurPortfolio",
  components: { PortfolioCard, Categories },
  data() {
    this.$store.dispatch("portfolio/GET_PORTFOLIO");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_CATEGORIES");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_IMAGE");
    return {
      chosen_category_id: -1,
      with_desc_name: true,
      with_desc_time: true,
      with_dev_time: true
    };
  },
  computed: {
    categories() {
      let categories = this.$store.getters["portfolio/portfolio_categories"];
      categories.unshift({ id: 0, name: "Все категории" });
      return this.$store.getters["portfolio/portfolio_categories"];
    },
    portfolio_cards() {
      if (this.$route.params.id == 0) {
        return this.$store.getters["portfolio/portfolio_card_all_categories"];
      } else {
        return this.$store.getters["portfolio/portfolio_card_by_id_category"](
          this.$route.params.id
        );
      }
    },
    category_by_id() {
      return this.$store.getters["portfolio/category_by_id"](
        this.$route.params.id
      );
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Портфолио";
      }
    }
  },
  methods: {
    choose_category_id: function(category_id) {
      this.chosen_category_id = category_id;
    }
  }
};
</script>

<style scoped></style>
