<template>
  <div class="newsPage">
    <v-container fluid class="no-indentation">
      <v-row class="newsPage">
        <v-col class="newsSlider no-indentation" cols="12" lg="6">
          <NewsSlider :id="this.$route.params.id"></NewsSlider>
        </v-col>
        <v-col class="newsContent no-indentation" cols="12" lg="6">
          <NewsContent :id="this.$route.params.id"></NewsContent>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import NewsSlider from "../components/ui/NewsSlider";
import NewsContent from "../components/ui/NewsContent";
export default {
  name: "NewsPage",
  components: { NewsSlider, NewsContent },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Новости";
      }
    }
  }
};
</script>
