
//import VueRouter from "vue-router";
import extra_routes from "./extra_routes.js";
//import store from "@/store";

const requireContext = require.context("../views", false, /.*\.vue$/);

var list_module = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
  .filter(el => el[1].default.name !== "auth");

var routes_ = [];
for (var i = 0; i < list_module.length; i++) {
  var el = list_module[i];
  routes_.push({
    path: "/" + el[0],
    name: el[1].default.name,
    component: require("../views/" + el[0] + ".vue").default
  });
}
for (var ii = 0; ii < extra_routes.length; ii++) {
  routes_.push(extra_routes[ii]);
}

export const routes = routes_;



export default routes;
