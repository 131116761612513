<template>
  <div v-if="news_image" class="sliderNewsPage">
    <SwiperComponent :options="swiperOption" class="no-indentation newsPageSliderSlider">
      <div
        class="parallax-bg"
        data-swiper-parallax="-23%"
      ></div>
      <SwiperSlideComponent v-for="img in news_image" v-bind:key="img.id" class="no-indentation">
        <v-img
          contain
          class="newsPageSliderImage"
          :src="getImage(img.url_image)"
        />
      </SwiperSlideComponent>
      <div
        class="swiper-pagination swiper-pagination-white"
      ></div>
      <div
        class="swiper-button-prev swiper-button-black"
      ></div>
      <div
        class="swiper-button-next swiper-button-black"
      ></div>
    </SwiperComponent>
  </div>
</template>

<script>
import "swiper/css";
import { Swiper as SwiperComponent, SwiperSlide as SwiperSlideComponent } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
export default {
  props: ["id"],
  components: {
    SwiperComponent,
    SwiperSlideComponent
  },
  data() {
    this.$store.dispatch("news/GET_NEWS_IMAGE");
    return {
      swiperOption: {
        speed: 1200,
        loop: false,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false
        },
        parallax: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  computed: {
    news_image() {
      return this.$store.getters["news/news_image"].filter(
        news_image => news_image.id_news == this.id
      );
    }
  },
  methods: {
    getImage(url) {
      "use strict";
      var start_url = window.location.href.split("/")[2];
      return "http://" + start_url + "/api/load_news_image?url=" + url;
      // return "http://127.0.0.1:5001/api/load_news_image?url=" + url;
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  font-size: 18px;
  color: #000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
  background-color: transparent !important;
  justify-content: space-around !important;
}
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-color: #000000;
  opacity: 0.3;
}
</style>
