<template>
  <div class="infoBlock">
    <img :src="this.itemImage" width="150" height="150" />
    <h1>
      {{ title }}
    </h1>
    <p>
      {{ content }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["img", "title", "content"],
  name: "InfoBlock",
  computed: {
    itemImage() {
      const StrImgName = String(this.img);
      return require("@/../public/img/" + StrImgName + ".svg");
    }
  }
};
</script>

<style scoped></style>
