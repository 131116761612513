
import {createRouter, createWebHistory} from 'vue-router'
import App from "./App.vue";
import { createApp } from 'vue';
import "./registerServiceWorker";
import routes from "./router";
import store from "./store";
 // import { aliases, mdi } from 'vuetify/lib/iconsets/mdi-svg'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { Swiper, SwiperSlide } from 'swiper/vue';



//import "@fortawesome/fontawesome-free/css/all.css";
//import "@fortawesome/fontawesome-free/js/all.js";


//import VueSocketIOExt from "vue-socket.io-extended";
//import io from "socket.io-client";

import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { createVuetify } from 'vuetify';  // Для Vuetify 3.x
import 'vuetify/styles';  // Обязательно импортировать стили
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const vuetify = createVuetify({
  components: {
    ...components,
    
  },
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  }
});
import JsonEditor from "vue-json-edit";

// inject barcode scanner with option (add sound effect)
// sound will trigger when it's already scanned
const app = createApp(App);


app.use(JsonEditor);
// Install components globally
app.component("ValidationObserver", ValidationObserver);
app.component("ValidationProvider", ValidationProvider);

const router = createRouter({
  base: process.env.BASE_URL,
  routes,
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
});

app.use(router);
app.use(store);
app.use(vuetify);
app.component('SwiperComponent', Swiper);
app.component('SwiperSlideComponent', SwiperSlide);
app.mount("#app");
