<template>
  <div class="newsBlock">
    <v-img
      class="imageCardNews"
      @click="pushNewsPage(id)"
      :src="getImage(src)"
    ></v-img>
    <div class="contentProject">
      <p class="dateNewsCard">{{ getDate(date) }}</p>
      <h2 class="titleNewsCard">
        <a @click="pushNewsPage(id)">{{ title }}</a>
      </h2>
      <p class="contentNewsCard">{{ content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: String,
    title: String,
    content: String,
    id: Number,
    src: String
  },
  name: "NewsBlock",
  methods: {
    pushNewsPage(value) {
      this.$router.push("/news_page/" + value).catch(() => {});
    },
    getDate(value) {
      return value.substr(0, 6) + value.substr(8, 10);
    },
    getImage(url) {
      "use strict";
      var start_url = window.location.href.split("/")[2];
      return "http://" + start_url + "/api/load_news_image?url=" + url;
      // return "http://127.0.0.1:5001/api/load_news_image?url=" + url;
    }
  }
};
</script>

<style scoped></style>
