<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="no-indentation px-lg-12 px-md-0">
      <v-col cols="12" lg="5" md="5" sm="11" class="pt-0">
        <h1 class="contentHeading">О компании</h1>
        <p class="contentText mr-10"></p>
        <h2>ООО "ИТ-Мозайка"</h2>
        <p>
          Компания ООО "ИТ-Мозаика", основанная в 2019 году, занимается
          разработкой передовых решений в области компьютерного программного
          обеспечения. Основной фокус компании направлен на интеграцию сложных
          систем для организаций в сфере медицины и фармацевтики.
        </p>

        <h2>Наши успешно завершённые проекты:</h2>
        <div class="projects">
          <ul>
            <li>Программа "Регистр пациентов с бесплодием"</li>
            <li>
              Программа "Аналитика и поиск государственных закупок России"
            </li>
            <li>
              Программа "Контроль давления, влажности и температуры в чистых
              помещениях для фармпроизводства"
            </li>
            <li>
              Программа "Автоматизация обмена данными между медицинскими
              учреждениями"
            </li>
            <li>Программа "Искусственный интеллект для рентгенографии"</li>
            <li>
              Программа "Интегратор заявок через портал 'ГОСУСЛУГИ' для
              медучреждений"
            </li>
          </ul>
        </div>

        <p>
          Кроме того, наша команда участвует в разработке финансового приложения
          <span class="highlight">"Kopilka.one"</span>, которое использует
          искусственный интеллект для оптимизации финансовых решений.
        </p>

        <p>
          В настоящее время компания анонсирует новый уникальный проект
          <span class="highlight">"Лекарство-Есть!"</span>, который не имеет
          аналогов в России. Эта инновационная система, использующая
          искусственный интеллект, помогает фармацевтам эффективно управлять
          продажами, прогнозировать спрос и оптимизировать ресурсы, заменяя
          целый ряд сотрудников, от менеджеров до юристов.
        </p>

        <h2>Контактная информация:</h2>
        <ul class="contact-info">
          <li>
            <strong>Электронная почта:</strong>
            <a href="mailto:it-mosaic@yandex.ru">it-mosaic@yandex.ru</a>
          </li>
          <li>
            <strong>Telegram:</strong>
            <a href="https://t.me/itmosaic" target="_blank">@itmosaic</a>
          </li>
          <li>
            <strong>ВКонтакте:</strong>
            <a href="https://vk.com/public205738953" target="_blank">перейти</a>
          </li>
          <li>
            <strong>WhatsApp:</strong>
            <a href="https://wa.me/89107879103" target="_blank">89107879103</a>
          </li>
          <li>
            <strong>Мобильный телефон:</strong>
            <a href="tel:+789107879103">89107879103</a>
          </li>
        </ul>

        <p>
          Мы предоставляем тестовые аккаунты для ознакомления с программой
          <span class="highlight">"Лекарство-Есть!"</span> и готовы помочь в
          настройке кассового оборудования.
        </p>

        <h2>Наши онлайн-ресурсы для клиентов:</h2>
        <div class="resources">
          <ul>
            <li>
              <strong>Система контроля версий:</strong>
              <a href="https://gitlab.it-mosaic.com" target="_blank">GitLab</a>
            </li>
            <li>
              <strong>Система хранения данных:</strong>
              <a href="https://disk.it-mosaic.com" target="_blank">Disk</a>
            </li>
          </ul>
        </div>

        <p>
          Эти ресурсы помогут вам не только хранить данные, но и отслеживать
          развитие программного обеспечения с новыми функциональными
          возможностями.
        </p>
        <p>
          Подробную и актуальную информацию о компании можно найти по
          <a
            href=" https://disk.it-mosaic.com/s/3JRMfRJ4cGEPKbJ"
            target="_blank"
            >следующей ссылке</a
          >
        </p>

        <p>
          Если у вас возникнут дополнительные вопросы или потребуется помощь, не
          стесняйтесь обращаться!
        </p>
      </v-col>
      <v-col>
        <h1 class="contentSubHeading pl-lg-3 pl-md-3 pl-sm-0">
          Компания в цифрах
        </h1>
        <v-row class="no-indentation ml-0">
          <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pt-0">
            <content-numeric
              subtitle="С 2018 года"
              unit="С 2018 года"
              content="На рынке"
            />
            <content-numeric
              subtitle="Более 70"
              unit="Более 70"
              content="закрытых проектов"
            />
            <content-numeric
              num="Более 7"
              unit="млн. рублей"
              content="Доход компании в год"
            />
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pt-0">
            <content-numeric
              unit="10 человек"
              content="Численность персонала в штате"
            />
            <content-numeric
              unit="более 100 %"
              content="Финансовый рост  компании с 2018 года"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ContentNumeric from "../components/ui/ContentNumeric";
import { mdiChevronRight } from "@mdi/js";
export default {
  name: "ourAbout",
  components: { ContentNumeric },
  props: ["submenu"],
  data() {
    return {
      svgAllNewsBtnPath: mdiChevronRight,
      toggle_none: undefined,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "О компании";
      },
    },
  },
};
</script>
