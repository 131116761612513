<template>
  <div class="newsFeed">
    <v-row class="pt-16 newsFeed">
      <v-col cols="12" class="mx-auto">
        <v-row>
          <v-col cols="12">
            <h1 class="titleNewsFeed">Лента</h1>
          </v-col>
        </v-row>
        <v-row>
          <div class="noNews" v-if="news_card.length == 0">
            Новостей пока нет
          </div>
        </v-row>
        <v-row class="newsCards">
          <NewsBlock
            v-for="current_news in news_card" v-bind:key="current_news.id"
            :src="current_news.url_image"
            :id="current_news.id"
            :date="current_news.date"
            :title="current_news.title"
            :content="current_news.text_news"
          />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NewsBlock from "@/components/ui/NewsBlock";
export default {
  components: { NewsBlock },
  name: "NewsFeed",
  data() {
    this.$store.dispatch("news/GET_NEWS");
    this.$store.dispatch("news/GET_NEWS_IMAGE");
    return {};
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Новости";
      }
    }
  },
  computed: {
    news_card() {
      return this.$store.getters["news/news_card"];
    }
  }
};
</script>

<style scoped></style>
