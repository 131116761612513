//import * as helper from "@/store/helpers";

// state
export const state = {
  showDialog: false,
};

// getters
export const getters = {
  isShowDialog: (state) => state.showDialog,
};

// mutations
export const mutations = {
  SET_IS_SHOW_DIALOG: (state, showDialog) => (state.showDialog = showDialog),
};

// actions
export const actions = {
  closeDialog: ({ commit }) => {
    commit("SET_IS_SHOW_DIALOG", false);
  },

  openDialog: ({ commit }) => {
    commit("SET_IS_SHOW_DIALOG", true);
  },
};
