import * as helper from "@/store/helpers";

// state
export const state = {
  prices: [],
  categories: []
};

// getters
export const getters = {
  prices: state => state.prices,
  categories: state => state.categories,
  categories_names: state => state.categories,
  prices_by_id_category: state => id_category => {
    return state.prices.filter(price => price.id_category == id_category);
  },
  category_by_id: state => id => {
    if (
      state.categories.filter(category => category.id == id)[0] === undefined
    ) {
      return {};
    } else {
      return state.categories.filter(category => category.id == id)[0];
    }
  }
};

// mutations
export const mutations = {
  SET_PRICES(state, prices) {
    state.prices = prices;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  }
};

// actions
export const actions = {
  GET_PRICES: async function({ commit }) {
    function Hello(data) {
      commit("SET_PRICES", data["response"]);
    }
    helper.api("prices", "", Hello);
  },
  GET_CATEGORIES: async function({ commit }) {
    function Hello(data) {
      commit("SET_CATEGORIES", data["response"]);
    }
    helper.api("category_prices", "", Hello);
    // commit("SET_CATEGORIES", { categories: data["response"] });
  }
};
