<template>
  <div class="projectBlock">
    <v-img @click="pushProductPage(title)" :src="getImage(img)"></v-img>
    <div class="contentProject">
      <h2>
        <a @click="pushProductPage(title)">{{ title }}</a>
      </h2>
      <p>{{ content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "img", "title", "content"],
  name: "ProjectCard",
  methods: {
    pushProductPage(value) {
      this.$router.push("/portfolio_page/" + value).catch(() => {});
    },
    getImage(url) {
      "use strict";
      var start_url = window.location.href.split("/")[2];
      return "http://" + start_url + "/api/load_portfolio_image?url=" + url;
      // return "http://127.0.0.1:5001/api/load_portfolio_image?url=" + url;
    }
  }
};
</script>

<style scoped></style>
