<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="no-indentation px-lg-12 px-md-0">
      <v-col cols="12" lg="12" md="12" sm="12">
        <ContentText title="Карьера в компании" />
        <div class="contentTextCareer mt-0">
          В ООО "ИТ-Мозаика" каждый сотрудник – полноценный партнер. Мы создаем
          комфортные условия работы, дружественную атмосферу, возможности для
          роста и построения карьеры, достойно вознаграждаем сотрудников и
          признаем тех, кто добивается высоких результатов.
        </div>
      </v-col>
    </v-row>
    <v-row class="no-indentation px-lg-12 px-md-0">
      <v-col cols="12" lg="5" md="5" sm="11">
        <h1 class="contentSubTitle mt-0">
          Мы предлагаем:
        </h1>
        <div class="contentTypesOfActivities">
          <v-icon class="my-lg-2 my-md-2 my-sm-2 mr-lg-2 mr-md-2 mr-sm-2"
            >mdi-map-marker</v-icon
          >
          Карьерный и профессиональный рост
        </div>
        <div class="contentTypesOfActivities">
          <v-icon
            class="mt-lg-1 mt-md-1 mb-lg-1 mb-md-1 mb-sm-1 mr-lg-2 mr-md-2 mr-sm-2"
            >mdi-map-marker</v-icon
          >
          Достойную заработную плату
        </div>
        <div class="contentTypesOfActivities">
          <v-icon class="my-lg-2 my-md-2 my-sm-2 mr-lg-2 mr-md-2 mr-sm-2"
            >mdi-map-marker</v-icon
          >
          Всестороннее обучение и развитие
        </div>
        <h1 class="contentSubTitle">Вопросы по трудоустройству?</h1>
        <div class="contentTextCareer">
          Контактный телефон:
          <a href="tel:+ 7(910)787-91-03">+ 7(910) 787-91-03</a>
        </div>
        <div class="contentTextCareer">
          Актуальную информацию о вакансиях компании Вы можете посмотреть на
          сайте
          <a href="https://smolensk.hh.ru/employer" target="_blank"
            >HeadHunter</a
          >
        </div>
        <div class="contentTextCareer">
          Вы также можете прислать ваше резюме, используя следующую форму:
        </div>
      </v-col>
      <v-col
        class="work_form_col"
        cols="12"
        xl="4"
        lg="5"
        md="5"
        sm="11"
        offset-lg="1"
        offset-md="1"
        offset-sm="0"
      >
        <work-form />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import "../assets/sass/app.scss";
import WorkForm from "../components/ui/WorkForm";
import { mdiFinance, mdiCash, mdiBrain } from "@mdi/js";
import ContentText from "@/components/ui/ContentText";
export default {
  name:"ourCareer",
  components: { ContentText, WorkForm },
  data() {
    return {
      svgPath1: mdiFinance,
      svgPath2: mdiCash,
      svgPath3: mdiBrain
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Карьера";
      }
    }
  }
};
</script>
