<template>
  <div class="prices">
    <v-row class="no-indentation">
      <v-col class="py-0" cols="10" offset="1">
        <div class="pricesHeader">Цены</div>
      </v-col>
      <v-col class="mb-0 px-0 px-sm-3 mt-10 mx-auto" cols="12" sm="10">
        <Categories
          v-on:change_category_id="choose_category_id($event)"
          :id_price_cat="this.$route.params.id"
          :Array_categories="categories"
        ></Categories>
      </v-col>
    </v-row>
    <div class="pricesTable">
      <v-row class="no-indentation pricesTableHeading">
        <v-col cols="10" class="mx-auto col-sm-9">
          <v-row>
            <v-col class="pt-0" cols="5">
              <div class="pricesColumnName">
                {{ category_by_id.name
                }}<span>
                  <sup style="font-weight: 400" v-if="with_desc_name">1</sup>
                </span>
              </div>
            </v-col>
            <v-col class="pt-0" cols="2" offset="1">
              <div class="pricesColumnName">Цена</div>
            </v-col>
            <v-col class="pt-0" cols="3" offset="1">
              <div v-if="with_dev_time" class="pricesColumnName">
                Срок разработки<span>
                  <sup
                    style="font-weight: 400"
                    v-if="with_desc_name && with_desc_time"
                    >2</sup
                  >
                  <sup
                    style="font-weight: 400"
                    v-if="!with_desc_name && with_desc_time"
                    >1</sup
                  >
                </span>
              </div>
            </v-col>
            <v-col cols="12">
              <hr class="no-indentation priceBorder" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="mx-auto col-sm-9">
          <ContentPrice
            v-for="price in prices_by_id_category" v-bind:key="price.id"
            class="mt-12"
            :name="price.name_project"
            :description="price.description"
            :role="price.role"
            :price="price.price"
            :time="price.dev_time"
          >
          </ContentPrice>
          <ContentPriceMobile
            v-for="price in prices_by_id_category"  v-bind:key="price.id"
            class="my-4"
            :name="price.name_project"
            :description="price.description"
            :role="price.role"
            :price="price.price"
            :time="price.dev_time"
          >
          </ContentPriceMobile>
        </v-col>
      </v-row>
    </div>
    <v-row class="no-indentation">
      <v-col class="mt-4 mt-sm-12 mx-auto" cols="10">
        <div
          v-if="with_desc_name || (with_desc_time && with_dev_time)"
          class="priceFootnotes"
        >
          <div class="priceFootnote">
            <sup>1</sup>
            <span v-if="with_desc_name"> {{ category_by_id.desc_name }}</span>
            <span v-else> {{ category_by_id.desc_time }}</span>
          </div>
          <div v-if="with_desc_name && with_desc_time" class="priceFootnote">
            <sup>2</sup> {{ category_by_id.desc_time }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Categories from "../components/ui/Categories.vue";
import ContentPrice from "../components/ui/ContentPrice.vue";
import ContentPriceMobile from "../components/ui/ContentPriceMobile.vue";
export default {
  components: { Categories, ContentPrice, ContentPriceMobile },
  name: "OurPrices",
  props: ["id_price"],
  data() {
    this.$store.dispatch("prices/GET_CATEGORIES");
    this.$store.dispatch("prices/GET_PRICES");
    return {
      with_desc_name: true,
      with_desc_time: true,
      with_dev_time: true
    };
  },
  computed: {
    categories() {
      return this.$store.getters["prices/categories"];
    },
    prices() {
      return this.$store.getters["prices/prices"];
    },
    prices_by_id_category() {
      return this.$store.getters["prices/prices_by_id_category"](
        this.$route.params.id
      );
    },
    category_by_id() {
      return this.$store.getters["prices/category_by_id"](
        this.$route.params.id
      );
    }
  },
  updated() {},
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Цены";
      }
    }
  },
  methods: {
    choose_category_id: function(category_id) {
      console.log(category_id);
      console.log();
      console.log(this.$route.params.id);
      this.chosen_category_id = category_id;
      this.check_desc();
      this.check_dev_time();
    },
    check_desc: function() {
      if (this.category_by_id.desc_name != null) this.with_desc_name = true;
      else this.with_desc_name = false;
      if (this.category_by_id.desc_time != null) this.with_desc_time = true;
      else this.with_desc_time = false;
    },
    check_dev_time: function() {
      this.with_dev_time = false;
      if (
        this.prices_by_id_category.find(item => item.dev_time == null) ==
        undefined
      ) {
        this.with_dev_time = true;
      }
    }
  }
};
</script>
